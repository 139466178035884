* {
	box-sizing: content-box;
}

body {
	margin: 0;
	font-family: "Raleway";
	box-sizing: content-box;
}

code {
	font-family: "Raleway", sans-serif;
}

li {
	list-style-type: none;
}

* ::-moz-selection {
	/* Code for Firefox */
	color: white;
	background: #00b093;
}

* ::selection {
	color: white;
	background: #00b093;
}

.comments-textarea__input {
	background-color: #fff;
	padding: 16.5px 14px;
	border-radius: 4px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #333;
	border: 1px solid #dedede;
	outline: none;
	border: none;
}

.comments-textarea__input:focus,
.comments-textarea__input:active {
	outline: none;
	border: none;
}

.comments-textarea__input::placeholder {
	color: #a4b0be;
	font-size: 16px;
}

.comments-textarea {
	vertical-align: top;
	width: 100%;
	background-color: rgb(250, 250, 250);
	max-height: 400px;
	overflow: visible;
	border-radius: 0px;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4375em;
	color: rgba(0, 0, 0, 0.87);
	box-sizing: border-box;
	cursor: text;
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	position: relative;
	border-radius: 4px;
	border: none;
	outline: none;
}

.comments-textarea:focus,
.comments-textarea:active {
	outline: none !important;
	border: none !important;
}

.comments-textarea__highlighter {
	padding: 16.5px 14px;
}
.comments-textarea__suggestions {
	z-index: 2;
}
.comments-textarea__suggestions__list {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.05);
	font-size: 14px;
	max-height: 400px;
	overflow: auto;
	z-index: 2;
	border-radius: 4px;
}
.comments-textarea__suggestions__item {
	padding: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.7);
}
.comments-textarea__suggestions__item:hover {
	color: #00b093;
}
.comments-textarea__suggestions__item:last-child {
	border-bottom: none !important;
}
.comments-textarea__suggestions__item—focused {
	background-color: #daf4fa;
}
